<template>
          <b-row>
            
            <b-col
              cols="12"
              class="mb-2"
            >
              <h5 class="mt-2" style="margin-bottom: 0px;">
                Putusan
              </h5>
            </b-col>
             <b-col
              cols="6"
              class="mb-2"
            >
              <div class="d-flex justify-content-end">
                <!-- <modal-instansi class="mr-1" :localData="localData" @emitGetDetailDataKerjasama="reloadDataKerjasama" @emitUpdateParaPihak="updateParaPihak" /> -->
              </div>
            </b-col>
             <b-col
              cols="6"
              class="mb-2"
            >
              <div class="d-flex justify-content-end">
                 <!-- <modal-narahubung :localData="localData" @emitGetDetailDataKerjasama="reloadDataKerjasama" @emitUpdateParaPihak="updateParaPihak"/> -->
              </div>
            </b-col>
            <b-col md="12">
              <!-- <validation-provider
                #default="{ errors }"
                name="Majelis/Hakim"
                rules="required"
              > -->
                <b-form-group
                  label="Majelis/Hakim"
                  label-for="Majelis/Hakim"

                >
                  <v-select
                    :disabled="$route.params.readonly == 'true'"
                    v-model="localData.hakim_majelis"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    class="w-100"
                    :reduce="val => val.value"
                    :options="majelisHakimOptions"
                  />
                  <!-- <b-form-invalid-feedback :state="errors.length > 0 ? false:null">
                    Majelis/hakim wajib diisi!
                  </b-form-invalid-feedback> -->
                </b-form-group>
              <!-- </validation-provider> -->
            </b-col>
          
            <!-- Pengadilan Tingkat 1 -->
            <!-- <br>
            {{pengadilan_tingkat_1}}
            <br>
            {{pengadilan_tingkat_2}}
            <br>
            {{pengadilan_tingkat_3}}
            <br> -->
            
            <b-col md="2">
              <div class="d-flex mt-1">
             
                  <label for="Pengadilan Tingkat 1" class="mr-4">
                    Pengadilan Tingkat Pertama
                  </label>
      
              </div>
            </b-col>
            <b-col md="1">
              <div class="d-flex mt-1">
<!--                   
                  <b-form-checkbox id="Pengadilan Tingkat 1" :disabled="$route.params.readonly == 'true'" v-model="localData.pengadilan_tingkat_1" @change="cekTerlapors1">
                  </b-form-checkbox> -->
                  <b-form-checkbox id="Pengadilan Tingkat 1" :disabled="$route.params.readonly == 'true'" v-model="localData.pengadilan_tingkat_1">
                  </b-form-checkbox>
      
              </div>
            </b-col>
            <b-col md="5">
              <b-form-group
           
              >
                <!-- <validation-provider
                  #default="{ errors }"
                  name="Putusan Pengadilan Tingkat 1"
                  rules="required"
                > -->
                  <b-form-input
                    id="putusan_pengadilan_tk_1"
                    v-model="localData.putusan_pengadilan_tk_1"
                    placeholder="Masukkan putusan pengadilan tingkat 1"
                    :disabled="$route.params.readonly == 'true'"
      
                  />

                  <!-- <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider> -->
                
              </b-form-group>

               <!-- <b-form-group
                v-else
              >
             
                  <b-form-input
                    id="putusan_pengadilan_tk_1"
                    v-model="localData.putusan_pengadilan_tk_1"
                    placeholder="Masukkan putusan pengadilan tingkat 1"
                    @change="tes"
                 
                  />

                
                
              </b-form-group> -->
            </b-col>
            <b-col md="4">
              <!-- <validation-provider
                #default="{ errors }"
                name="Majelis/Hakim"
                rules="required"
              > -->
                <b-form-group
                 
                >
                  <v-select
                    id="badan_peradilan_tk_1"
                    :disabled="$route.params.readonly == 'true'"
                    v-model="localData.badan_peradilan_tk_1"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    class="w-100"
                    :reduce="val => val.value"
                    
                    :options="localData.pengadilan_tingkat_1 ? badanPeradilanTingkat1Options : []"

                
                  />
                  <!-- <b-form-invalid-feedback :state="errors.length > 0 ? false:null">
                    Majelis/hakim wajib diisi!
                  </b-form-invalid-feedback> -->
                </b-form-group>
              <!-- </validation-provider> -->
            </b-col>






            <!-- <b-col md="12" v-if="this.terlapors_1 == true"> -->
            <b-row class="mr-1 mt-1 mb-1 col-12" v-if="this.localData.pengadilan_tingkat_1 == true">
              <b-col
                    cols="3"
                    class="mb-4"
                  >
                    <div class="d-flex justify-content-start">
                      
                    </div>
              </b-col>
                  <b-col md="9" v-if="!$route.params.readonly">
                    <b-form-group>
                      <b-button class="btn" v-b-modal.modal_pilih_terlapor_1 variant="outline-primary" @click="updateDataLaporanTerlapor(1)">Pilih Terlapor</b-button>
                      <b-modal id="modal_pilih_terlapor_1" size="xl" title="Terlapor" ref="btnClose" hide-footer v-if="$route.params.laporan_id  && !buttonOff">
                        <tambah-terlapor  :localDatas="localData" :tingkat_pengadilan="tingkat_pengadilan" @emitlaporanTerlaporReload="laporanTerlaporReload" />
                      </b-modal>
                    </b-form-group>
                  </b-col>
           
              <!-- <div v-if="parseInt(localData.total_terlapors) < 4">
                <b-row class="mr-1" v-for="item in localData.terlapors" :key="item.id">

                
                    <b-col
                      cols="3"
                      class="mb-0"
                    >
                      <div class="d-flex justify-content-start">
                        
                      </div>
                    </b-col>
                    <b-col
                      cols="9"
                      class="mb-0"
                    >
                      <div class="d-flex justify-content-start">
                        <h6>Terlapor:</h6>
                      </div>
                    </b-col>




                    
                    <b-col
                      cols="3"
                      class="mb-2"
                    >
                      <div class="d-flex justify-content-start">
                        
                      </div>
                    </b-col>


                    <b-col md="5">
                      <b-form-group>
                        <label for="nama">Nama:</label>
                        <b-form-input 
                                      id="nama"
                                      disabled
                                      :value="item.nama"
                        />
                      </b-form-group>
                    </b-col>
                    <b-col md="4">
                      <b-form-group>
                        <label for="nip">NIP:</label>
                        <b-form-input 
                                      id="nip"
                                      disabled
                                      :value="item.nip"
                        /> 
                      </b-form-group>
                    </b-col>



                    <b-col
                      cols="3"
                      class="mb-2"
                    >
                      <div class="d-flex justify-content-start">
                        
                      </div>
                    </b-col>


                    <b-col md="5">
                      <b-form-group>
                        <label for="jenis_kelamin">Jenis Kelamin:</label>
                        <b-form-input 
                                      id="jenis_kelamin"
                                      disabled
                                      :value="item.jenis_kelamin"
                        />
                      </b-form-group>
                    </b-col>
                    <b-col md="4">
                      <b-form-group>
                        <label for="jabatan">Jabatan:</label>
                        <b-form-input 
                                      id="jabatan"
                                      disabled
                                      :value="item.jabatan"
                        />
                      </b-form-group>
                    </b-col>


                    <b-col
                      cols="3"
                      class="mb-2"
                    >
                      <div class="d-flex justify-content-start">
                        
                      </div>
                    </b-col>


              
                    <b-col md="9">
                      <b-form-group>
                        <label for="jumlah_dilaporkan">Jumlah Dilaporkan:</label>
                        <b-form-input 
                                      id="jumlah_dilaporkan"
                                      disabled
                                      :value="item.jumlah_dilaporkan"
                        />
                      </b-form-group>
                    </b-col>
                  

                    <b-col
                      cols="12"
                      class="mb-2"
                    >
                      <div class="d-flex justify-content-start">
                        
                      </div>
                    </b-col>


                </b-row>
              </div> -->
                <div class="mx-2 mb-2 mt-2" v-for="(laporan_terlapors_1, index) in localData.laporan_terlapors_1" :key="laporan_terlapors_1.id">
                  <laporan-terlapor @emitReloadData="laporanTerlaporReload" :localDatas="localData" :index="index" :localData="laporan_terlapors_1" :jenisLaporanOptions="jenisLaporanOptions"  :key="laporanTerlaporsReload"/>
                </div>
            </b-row>
            <!-- </b-col> -->
            





            <!-- Pengadilan Tingkat 2 -->
            <b-col md="2">
                <div class="d-flex mt-1">
                
                    <label for="Pengadilan Tingkat 2" class="mr-4">
                      Pengadilan Tingkat Banding
                    </label>

                </div>
            </b-col>
            <b-col md="1">
                <div class="d-flex mt-1">
                    <b-form-checkbox id="Pengadilan Tingkat 2" :disabled="$route.params.readonly == 'true'" v-model="localData.pengadilan_tingkat_2" @change="cekTerlapors2">

                    </b-form-checkbox>        
                </div>
            </b-col>
            <b-col md="5">
               <b-form-group

              >
              <!-- <validation-provider
                  #default="{ errors }"
                  name="Putusan Pengadilan Tingkat 2"
                  rules="required"
                > -->
                  <b-form-input
                    id="putusan_pengadilan_tk_2"
                    placeholder="Masukkan putusan pengadilan tingkat 2"
                    v-model="localData.putusan_pengadilan_tk_2"
                    :disabled="$route.params.readonly == 'true'"

                  />
                  <!-- <b-form-input
                    id="putusan_pengadilan_tk_2"
                    placeholder="Masukkan putusan pengadilan tingkat 2"
                    v-model="localData.putusan_pengadilan_tk_2"
                    v-else
                  /> -->
                  <!-- <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider> -->
                
              </b-form-group>
            </b-col>
            <b-col md="4">
            
                <b-form-group
                 
                >
                  <v-select
                    id="badan_peradilan_tk_2"
                    :disabled="$route.params.readonly == 'true'"
                    v-model="localData.badan_peradilan_tk_2"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    class="w-100"
                    :reduce="val => val.value"
                    :options="localData.pengadilan_tingkat_2 ? badanPeradilanTingkat2Options : []" 

                  />
                
                </b-form-group>
          
            </b-col>



            <b-row class="mr-1 mt-1 mb-1 col-12" v-if="this.localData.pengadilan_tingkat_2 == true">
              <b-col
                    cols="3"
                    class="mb-4"
                  >
                    <div class="d-flex justify-content-start">
                      
                    </div>
              </b-col>
                  <b-col md="9" v-if="!$route.params.readonly">
                    <b-form-group>
                      <b-button class="btn" v-b-modal.modal_pilih_terlapor_2 variant="outline-primary" @click="updateDataLaporanTerlapor(2)">Pilih Terlapor</b-button>
                      <b-modal id="modal_pilih_terlapor_2" size="xl" title="Terlapor" ref="btnClose" hide-footer v-if="$route.params.laporan_id  && !buttonOff">
                        <tambah-terlapor :localDatas="localData" :tingkat_pengadilan="tingkat_pengadilan" @emitlaporanTerlaporReload="laporanTerlaporReload" />
                      </b-modal>
                    </b-form-group>
                  </b-col>
           
             
                <div class="mx-2 mb-2 mt-2" v-for="(laporan_terlapors_2, index) in localData.laporan_terlapors_2" :key="laporan_terlapors_2.id">
                  <laporan-terlapor @emitReloadData="laporanTerlaporReload"  :index="index" :localData="laporan_terlapors_2" :jenisLaporanOptions="jenisLaporanOptions"  :key="laporanTerlaporsReload"/>
                </div>
            </b-row>

            <!-- <b-col md="12" v-if="this.terlapors_2 == true">

           
            <div v-if="parseInt(localData.total_terlapors) < 4">
              <b-row class="mr-1" v-for="item in localData.terlapors" :key="item.id">

              
                  <b-col
                    cols="3"
                    class="mb-0"
                  >
                    <div class="d-flex justify-content-start">
                      
                    </div>
                  </b-col>
                  <b-col
                    cols="9"
                    class="mb-0"
                  >
                    <div class="d-flex justify-content-start">
                      <h6>Terlapor:</h6>
                    </div>
                  </b-col>




                  
                  <b-col
                    cols="3"
                    class="mb-2"
                  >
                    <div class="d-flex justify-content-start">
                      
                    </div>
                  </b-col>


                  <b-col md="5">
                    <b-form-group>
                      <label for="nama">Nama:</label>
                      <b-form-input 
                                    id="nama"
                                    disabled
                                    :value="item.nama"
                      />
                    </b-form-group>
                  </b-col>
                  <b-col md="4">
                    <b-form-group>
                      <label for="nip">NIP:</label>
                      <b-form-input 
                                    id="nip"
                                    disabled
                                    :value="item.nip"
                      /> 
                    </b-form-group>
                  </b-col>



                  <b-col
                    cols="3"
                    class="mb-2"
                  >
                    <div class="d-flex justify-content-start">
                      
                    </div>
                  </b-col>


                  <b-col md="5">
                    <b-form-group>
                      <label for="jenis_kelamin">Jenis Kelamin:</label>
                      <b-form-input 
                                    id="jenis_kelamin"
                                    disabled
                                    :value="item.jenis_kelamin"
                      />
                    </b-form-group>
                  </b-col>
                  <b-col md="4">
                    <b-form-group>
                      <label for="jabatan">Jabatan:</label>
                      <b-form-input 
                                    id="jabatan"
                                    disabled
                                    :value="item.jabatan"
                      />
                    </b-form-group>
                  </b-col>


                  <b-col
                    cols="3"
                    class="mb-2"
                  >
                    <div class="d-flex justify-content-start">
                      
                    </div>
                  </b-col>


            
                  <b-col md="9">
                    <b-form-group>
                      <label for="jumlah_dilaporkan">Jumlah Dilaporkan:</label>
                      <b-form-input 
                                    id="jumlah_dilaporkan"
                                    disabled
                                    :value="item.jumlah_dilaporkan"
                      />
                    </b-form-group>
                  </b-col>
                

                  <b-col
                    cols="12"
                    class="mb-2"
                  >
                    <div class="d-flex justify-content-start">
                      
                    </div>
                  </b-col>


              </b-row>
            </div>
            <div class="mx-2 mb-2 mt-2" v-else>
              <terlapor :buttonOff=true />
            </div>
            </b-col> -->
            




            <!-- Pengadilan Tingkat 3 -->
            <b-col md="2">
              <div class="d-flex mt-1">
             
                <label for="Pengadilan Tingkat 3" class="mr-4">
                  Pengadilan Tingkat Kasasi/Peninjauan Kembali
                </label>
               
             </div>
            </b-col>
            <b-col md="1">
              <div class="d-flex mt-1">
               
                <b-form-checkbox id="Pengadilan Tingkat 3" :disabled="$route.params.readonly == 'true'" v-model="localData.pengadilan_tingkat_3" @change="cekTerlapors3">

                </b-form-checkbox>
         
             </div>
            </b-col>
            <b-col md="5">
              <b-form-group

              >
              <!-- <validation-provider
                  #default="{ errors }"
                  name="Putusan Pengadilan Tingkat 3"
                  rules="required"
                > -->
               
                  <b-form-input
                    id="putusan_pengadilan_tk_3"
                    placeholder="Masukkan putusan pengadilan tingkat 3"
                    v-model="localData.putusan_pengadilan_tk_3"
                    :disabled="$route.params.readonly == 'true'"
                  />
                  <!-- <b-form-input
                    id="putusan_pengadilan_tk_3"
                    placeholder="Masukkan putusan pengadilan tingkat 3"
                    v-model="localData.putusan_pengadilan_tk_3"
                    v-else
                  /> -->

                <!-- <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider> -->
                
              </b-form-group>
            </b-col>
            <b-col md="4">
            
                <b-form-group
                 
                >
                  <v-select id="badan_peradilan_tk_3"
                    :disabled="$route.params.readonly == 'true'"
                    v-model="localData.badan_peradilan_tk_3"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    class="w-100"
                    :reduce="val => val.value"
                    :options="localData.pengadilan_tingkat_3 ? badanPeradilanTingkat3Options : []" 

                  />
                
                </b-form-group>
          
            </b-col>


            <b-row class="mr-1 mt-1 mb-1 col-12" v-if="this.localData.pengadilan_tingkat_3 == true">
              <b-col
                    cols="3"
                    class="mb-4"
                  >
                    <div class="d-flex justify-content-start">
                      
                    </div>
              </b-col>
                  <b-col md="9" v-if="!$route.params.readonly">
                    <b-form-group>
                      <b-button class="btn" v-b-modal.modal_pilih_terlapor_3 variant="outline-primary" @click="updateDataLaporanTerlapor(3)">Pilih Terlapor</b-button>
                      <b-modal id="modal_pilih_terlapor_3" size="xl" title="Terlapor" ref="btnClose" hide-footer v-if="$route.params.laporan_id  && !buttonOff">
                        <tambah-terlapor  :localDatas="localData" :tingkat_pengadilan="tingkat_pengadilan" @emitlaporanTerlaporReload="laporanTerlaporReload" />
                      </b-modal>
                    </b-form-group>
                  </b-col>
           
             
                <div class="mx-2 mb-2 mt-2" v-for="(laporan_terlapors_3, index) in localData.laporan_terlapors_3" :key="laporan_terlapors_3.id">
                  <laporan-terlapor @emitReloadData="laporanTerlaporReload" :index="index" :localData="laporan_terlapors_3" :jenisLaporanOptions="jenisLaporanOptions"  :key="laporanTerlaporsReload"/>
                </div>
            </b-row>

           <!-- <b-col md="12" v-if="this.terlapors_3 == true">

           
            <div v-if="parseInt(localData.total_terlapors) < 4">
              <b-row class="mr-1" v-for="item in localData.terlapors" :key="item.id">

              
                  <b-col
                    cols="3"
                    class="mb-0"
                  >
                    <div class="d-flex justify-content-start">
                      
                    </div>
                  </b-col>
                  <b-col
                    cols="9"
                    class="mb-0"
                  >
                    <div class="d-flex justify-content-start">
                      <h6>Terlapor:</h6>
                    </div>
                  </b-col>




                  
                  <b-col
                    cols="3"
                    class="mb-2"
                  >
                    <div class="d-flex justify-content-start">
                      
                    </div>
                  </b-col>


                  <b-col md="5">
                    <b-form-group>
                      <label for="nama">Nama:</label>
                      <b-form-input 
                                    id="nama"
                                    disabled
                                    :value="item.nama"
                      />
                    </b-form-group>
                  </b-col>
                  <b-col md="4">
                    <b-form-group>
                      <label for="nip">NIP:</label>
                      <b-form-input 
                                    id="nip"
                                    disabled
                                    :value="item.nip"
                      /> 
                    </b-form-group>
                  </b-col>



                  <b-col
                    cols="3"
                    class="mb-2"
                  >
                    <div class="d-flex justify-content-start">
                      
                    </div>
                  </b-col>


                  <b-col md="5">
                    <b-form-group>
                      <label for="jenis_kelamin">Jenis Kelamin:</label>
                      <b-form-input 
                                    id="jenis_kelamin"
                                    disabled
                                    :value="item.jenis_kelamin"
                      />
                    </b-form-group>
                  </b-col>
                  <b-col md="4">
                    <b-form-group>
                      <label for="jabatan">Jabatan:</label>
                      <b-form-input 
                                    id="jabatan"
                                    disabled
                                    :value="item.jabatan"
                      />
                    </b-form-group>
                  </b-col>


                  <b-col
                    cols="3"
                    class="mb-2"
                  >
                    <div class="d-flex justify-content-start">
                      
                    </div>
                  </b-col>


            
                  <b-col md="9">
                    <b-form-group>
                      <label for="jumlah_dilaporkan">Jumlah Dilaporkan:</label>
                      <b-form-input 
                                    id="jumlah_dilaporkan"
                                    disabled
                                    :value="item.jumlah_dilaporkan"
                      />
                    </b-form-group>
                  </b-col>
                

                  <b-col
                    cols="12"
                    class="mb-2"
                  >
                    <div class="d-flex justify-content-start">
                      
                    </div>
                  </b-col>


              </b-row>
            </div>
            <div class="mx-2 mb-2 mt-2" v-else>
              <terlapor :buttonOff=true />
            </div>
           </b-col> -->
          



          </b-row>
</template>

<script>
import { FormWizard, TabContent } from 'vue-form-wizard'
import vSelect from 'vue-select'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import 'vue-form-wizard/dist/vue-form-wizard.min.css'
import Kerjasama from '@/views/laporan/list/List.vue'
import Terlapor from '@/views/terlapor/list/List.vue'
import LaporanTerlapor from '@/views/laporan/wizard/component/LaporanTerlapor.vue'
import {
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BFormInvalidFeedback,
  BButton,
  BFormTextarea,
  BModal,
  BFormFile,
  BMedia,
  BMediaBody,
  BFormCheckbox,
} from 'bootstrap-vue'
// import {
//   majelisHakimOptions,
// } from './FormWizard.vue'
import {
  hapusPihak,
  postLaporanTerlapor,
} from '@/connection-api/laporan'
import { required, email } from '@validations'
import flatPickr from "vue-flatpickr-component";
import { Indonesian } from "flatpickr/dist/l10n/id.js";
import 'flatpickr/dist/flatpickr.css';
import ModalInstansi from "./ModalInstansi.vue"
import ModalNarahubung from "./ModalNarahubung.vue"
import TambahTerlapor from '@/views/terlapor/tambah-terlapor/list/List.vue'
// import ModalUnitUtama from "./ModalUnitUtama.vue"

export default {
  components: {
    ValidationProvider,
    ValidationObserver,
    FormWizard,
    TabContent,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    vSelect,
    BFormInvalidFeedback,
    BFormTextarea,
    // eslint-disable-next-line vue/no-unused-components
    ToastificationContent,
    BButton,
    flatPickr,
    BModal,
    BFormFile,
    BMedia,
    BMediaBody,
    Kerjasama,
    ModalInstansi,
    ModalNarahubung,
    // ModalUnitUtama,
     BFormCheckbox,
     Terlapor,
     LaporanTerlapor,
     TambahTerlapor,
  },
  data() {
    return {
      configs: {
        basic: {},
        wrap: {
          wrap: true,
          dateFormat: "d-m-Y",
          locale: Indonesian,
        },
      },
      required,
      userData: JSON.parse(localStorage.getItem('userData')),
      pengadilan_tingkat_1: null,
      pengadilan_tingkat_2: null,
      pengadilan_tingkat_3: null,
      terlapors: null,
      terlapors_1: false,
      terlapors_2: false,
      terlapors_3: false,
      tingkat_pengadilan: null,
      laporanTerlaporsReload: 0,
    }
  },
  props: {
    majelisHakimOptions: {},
    jenisLaporanOptions: {},
    // dugaanPelanggaranOptions: {},
    badanPeradilanTingkat1Options: {},
    badanPeradilanTingkat2Options: {},
    badanPeradilanTingkat3Options: {},
    localData: {},
    buttonOff: {},
  },
  methods: {
    async onchangeJenisLaporan(jenis_laporan_id)
    {
      // this.$emit('emitLoadDugaanPelanggaran', jenis_laporan_id)
      // console.log(jenis_laporan_id)
    },
    async updateDataLaporanTerlapor(tingkat){
      if(tingkat == 1){
        this.tingkat_pengadilan = 1;
      }else if(tingkat == 2){
        this.tingkat_pengadilan = 2;
      }else if(tingkat == 3){
        this.tingkat_pengadilan = 3;
      }

      // this.localData.laporan_terlapors
      const response = await postLaporanTerlapor(this.localData)

      // console.log(response)
    },
    cekTerlapors1() {
      if (this.terlapors_1 == false) {
        this.terlapors_1 = true
        // console.log(this.terlapors_1)
      }else{
        this.terlapors_1 = false
        // console.log(this.terlapors_1)
      }
    },
    cekTerlapors2() {
      if (this.terlapors_2 == false) {
        this.terlapors_2 = true
        // console.log(this.terlapors_2)
      }else{
        this.terlapors_2 = false
        // console.log(this.terlapors_2)
      }
    },
    cekTerlapors3() {
      if (this.terlapors_3 == false) {
        this.terlapors_3 = true
        // console.log(this.terlapors_3)
      }else{
        this.terlapors_3 = false
        // console.log(this.terlapors_3)
      }
    },
    // async onchangeJenisLaporan()
    // {
    //   this.$emit('emitLoadDugaanPelanggaran', this.localData.jenis_laporan_id)
    // },
    updateParaPihak() {
      this.$emit('emitUpdateParaPihak')
    },
    async changeBadanPeradilan(val)
    {
      // console.log(val)
      this.localData.badan_peradilan_tk_1 = val
      // const data = {
      //   badan_peradilan_tk_1: val
      // }
      //  this.badanPeradilanTingkat1Options = {
      //   selected: 'id',
      //   options: [
      //     { value: null, label: '--Pilih Kota --' },
      //   ],
      // }
      // this.kotaOptions = {
      //   selected: 'id',
      //   options: [
      //     { value: null, label: '--Pilih Kota --' },
      //   ],
      // }
      // const respKota = await getKota(data)
      // this.mappingSelectOption(val, this.badanPeradilanTingkat1Options.options)
    },
    async hapusData(id){
      if(window.confirm('Apakah anda yakin?'))
      {
        const resp = await hapusPihak(id)
        if(resp.data.success === true)
        {
          this.makeToast('success', resp.data.message)
          this.$emit('emitGetDetailDataKerjasama', this.$route.params.id)
        }
      }
    },
    reloadDataKerjasama()
    {
      this.$emit('emitGetDetailDataKerjasama', this.$route.params.id)
    },
    laporanTerlaporReload(){
      this.$emit('emitgetDetailDataLaporan', this.$route.params.laporan_id);
      this.laporanTerlaporsReload += 1;
      
    },
    makeToast(variant = null, message = null) {
      this.$bvToast.toast(message, {
        title: 'Kerjasama',
        variant,
        solid: true,
        autoHideDelay: 5000,
      })
    },
  }
}
</script>
