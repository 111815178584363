<template>
      <b-row class="mr-1">

              
                  <b-col
                    cols="3"
                    class="mb-0"
                  >
                    <div class="d-flex justify-content-start">
                      
                    </div>
                  </b-col>
                  <b-col
                    cols="9"
                    class="mb-0"
                  >
                    <div class="d-flex justify-content-start">
                      <h6>Terlapor {{ index+1 }}:</h6>
                    </div>
                  </b-col>

                  <b-col
                    cols="3"
                    class="mb-0"
                  >
                    <div class="d-flex justify-content-start">
                      
                    </div>
                  </b-col>

                  <b-col
                    cols="9"
                    class="mb-0"
                    
                  >
                    <div class="d-flex justify-content-end" v-if="!$route.params.readonly">
                      <b-button class="mr-1 btn-icon btn btn-relief-danger" variant="danger" v-b-modal="`modal-unselect-terlapor-${localData.terlapor_id}`" title="Hapus Terlapor">
                        <feather-icon icon="XIcon" />
                          <!-- <b-modal :id="'modal-laporan-terlapor-'+data.item.id" size="lg" scrollable title="Daftar Putusan">
                            <putusan-terlapor :laporan_id="$route.params.laporan_id" :terlapor_id="data.item.id" @emitRefetchPutusanTerlapor="refetchData"/>
                          </b-modal> -->

                      </b-button>
                    </div>

                    <div>
                      <b-modal :id="`modal-unselect-terlapor-${localData.terlapor_id}`" centered title="Konfirmasi">
                        <div class="d-flex justify-content-center">
                          <h4 class="my-4">Apakah anda yakin ingin menghapus terlapor atas nama {{this.localData.nama}} dari laporan Anda?</h4>
                        </div>
                        <template #modal-footer>
                          <b-button variant="danger" @click="unselectTerlapor(localData.terlapor_id)">Hapus</b-button>
                          <b-button variant="secondary" @click="$bvModal.hide(`modal-unselect-terlapor-${localData.terlapor_id}`)">Batal</b-button>
                        </template>
                      </b-modal>
                    </div>



                  </b-col>


                  




                  
                 

                  <b-col
                    cols="3"
                    class="mb-2"
                  >
                    <div class="d-flex justify-content-start">
                      
                    </div>
                  </b-col>


                  <b-col md="5">
                    <b-form-group>
                      <label for="nama">Nama:</label>
                      <b-form-input 
                                    id="nama"
                                    disabled
                                    :value="localData.nama"
                      />
                    </b-form-group>
                  </b-col>
                  <b-col md="4">
                    <b-form-group>
                      <label for="nip">NIP:</label>
                      <b-form-input 
                                    id="nip"
                                    disabled
                                    :value="localData.nip"
                      /> 
                    </b-form-group>
                  </b-col>



                  <b-col
                    cols="3"
                    class="mb-2"
                  >
                    <div class="d-flex justify-content-start">
                      
                    </div>
                  </b-col>


                  <b-col md="5">
                    <b-form-group>
                      <label for="jenis_kelamin">Jenis Kelamin:</label>
                      <b-form-input 
                                    id="jenis_kelamin"
                                    disabled
                                    :value="localData.jenis_kelamin"
                      />
                    </b-form-group>
                  </b-col>
                  <b-col md="4">
                    <b-form-group>
                      <label for="jabatan">Jabatan:</label>
                      <b-form-input 
                                    id="jabatan"
                                    disabled
                                    :value="localData.jabatan"
                      />
                    </b-form-group>
                  </b-col>


                  <b-col
                    cols="3"
                    class="mb-2"
                  >
                    <div class="d-flex justify-content-start">
                      
                    </div>
                  </b-col>
                  <b-col md="9">
              <!-- <validation-provider
                #default="{ errors }"
                name="Jenis Laporan"
                rules="required"
              > -->
                <b-form-group
                  label="Jenis Laporan"
                  label-for="Jenis Laporan"

                >
                  <v-select
                    :disabled="$route.params.readonly == 'true'"
                    v-model="localData.jenis_laporan_id"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    class="w-100"
                    :reduce="val => val.value"
                    :options="jenisLaporanOptions"
                    @input="(val) => onchangeJenisLaporan(val)"
                    id="jenis_laporan_id"
                  />
                  <!-- <b-form-invalid-feedback :state="errors.length > 0 ? false:null">
                    Jenis Laporan wajib diisi!
                  </b-form-invalid-feedback> -->
                </b-form-group>
              <!-- </validation-provider> -->
            </b-col>



                  <b-col
                    cols="3"
                    class="mb-2"
                  >
                    <div class="d-flex justify-content-start">
                      
                    </div>
                  </b-col>


                  <b-col md="9">
              <!-- <validation-provider
                #default="{ errors }"
                name="Dugaan Pelanggaran"
                rules="required"
              > -->
                <b-form-group
                  label="Dugaan Pelanggaran"
                  label-for="Dugaan Pelanggaran"

                >
                  <v-select
                    :disabled="$route.params.readonly == 'true'"
                    v-model="localData.dugaan_pelanggaran_id"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    class="w-100"
                    :reduce="val => val.value"
                    :options="dugaanPelanggaranOptions.options"
                    id="dugaan_pelanggaran_id"
                  />
                  <!-- <b-form-invalid-feedback :state="errors.length > 0 ? false:null">
                    Dugaan Pelanggaran wajib diisi!
                  </b-form-invalid-feedback> -->
                </b-form-group>
              <!-- </validation-provider> -->
            </b-col>


                  <b-col
                    cols="3"
                    class="mb-2"
                  >
                    <div class="d-flex justify-content-start">
                      
                    </div>
                  </b-col>


            
                  <b-col md="9">
                    <b-form-group>
                      <label for="jumlah_dilaporkan">Jumlah Dilaporkan:</label>
                      <b-form-input 
                                    id="jumlah_dilaporkan"
                                    disabled
                                    :value="localData.jumlah_dilaporkan"
                      />
                    </b-form-group>
                  </b-col>
                

                  <b-col
                    cols="12"
                    class="mb-2"
                  >
                    <div class="d-flex justify-content-start">
                      
                    </div>
                  </b-col>


      </b-row>
</template>

<script>
import { FormWizard, TabContent } from 'vue-form-wizard'
import vSelect from 'vue-select'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import 'vue-form-wizard/dist/vue-form-wizard.min.css'
import Kerjasama from '@/views/laporan/list/List.vue'
import Terlapor from '@/views/terlapor/list/List.vue'
import {
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BFormInvalidFeedback,
  BButton,
  BFormTextarea,
  BModal,
  BFormFile,
  BMedia,
  BMediaBody,
  BFormCheckbox,
} from 'bootstrap-vue'
// import {
//   majelisHakimOptions,
// } from './FormWizard.vue'
import {
  hapusPihak,
} from '@/connection-api/laporan'
import {
  getDugaanPelanggaran,
  unselectTerlapor,
} from '@/connection-api/master'
import { required, email } from '@validations'
import flatPickr from "vue-flatpickr-component";
import { Indonesian } from "flatpickr/dist/l10n/id.js";
import 'flatpickr/dist/flatpickr.css';
import ModalInstansi from "./ModalInstansi.vue"
import ModalNarahubung from "./ModalNarahubung.vue"

// import ModalUnitUtama from "./ModalUnitUtama.vue"

export default {
  components: {
    ValidationProvider,
    ValidationObserver,
    FormWizard,
    TabContent,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    vSelect,
    BFormInvalidFeedback,
    BFormTextarea,
    // eslint-disable-next-line vue/no-unused-components
    ToastificationContent,
    BButton,
    flatPickr,
    BModal,
    BFormFile,
    BMedia,
    BMediaBody,
    Kerjasama,
    ModalInstansi,
    ModalNarahubung,
    // ModalUnitUtama,
     BFormCheckbox,
     Terlapor,

  },
  data() {
    return {
      configs: {
        basic: {},
        wrap: {
          wrap: true,
          dateFormat: "d-m-Y",
          locale: Indonesian,
        },
      },
      required,
      userData: JSON.parse(localStorage.getItem('userData')),
      pengadilan_tingkat_1: null,
      pengadilan_tingkat_2: null,
      pengadilan_tingkat_3: null,
      terlapors: null,
      terlapors_1: false,
      terlapors_2: false,
      terlapors_3: false,
      dugaanPelanggaranOptions: {
        selected: 'id',
        options: [
          { value: null, label: '--Pilih Jenis Dugaan Pelanggaran--' },
        ],
      },
   
    }
  },
  created(){
    // console.log(this.localData)
    if(this.localData.jenis_laporan_id !== null){
      // console.log(this.localData)
      // console.log(localData)
      this.onchangeJenisLaporan(this.localData.jenis_laporan_id)
    }
  },
  props: {
    majelisHakimOptions: {},
    jenisLaporanOptions: {},
    badanPeradilanTingkat1Options: {},
    badanPeradilanTingkat2Options: {},
    badanPeradilanTingkat3Options: {},
    localData: {},
    localDatas: {},
    index: {},
  },

 
 
  methods: {
    async unselectTerlapor(terlapor_id){
      // if(window.confirm('Apakah anda yakin?'))
      // {
         const data = {
            terlapor_id: terlapor_id,
            laporan_id: this.$route.params.laporan_id,
          }

          // console.log(data)
          const response = await unselectTerlapor(data)
          this.$emit('emitReloadData', this.$route.params.laporan_id)
          // console.log('response')
      // }
    },
    async onchangeJenisLaporan(jenisLaporanId)
    {
      const data = {
        jenislaporan_id: jenisLaporanId
      }
      this.dugaanPelanggaranOptions = {
        selected: 'id',
        options: [
          { value: null, label: '--Pilih Dugaan Pelanggaran --' },
        ],
      }
      const respDugaanPelanggaran = await getDugaanPelanggaran(data)
      this.mappingSelectOption(respDugaanPelanggaran.data, this.dugaanPelanggaranOptions.options)
      // this.localData.dugaan_pelanggaran_id = null
    },
    
    cekTerlapors1() {
      if (this.terlapors_1 == false) {
        this.terlapors_1 = true
        // console.log(this.terlapors_1)
      }else{
        this.terlapors_1 = false
        // console.log(this.terlapors_1)
      }
    },
    cekTerlapors2() {
      if (this.terlapors_2 == false) {
        this.terlapors_2 = true
        // console.log(this.terlapors_2)
      }else{
        this.terlapors_2 = false
        // console.log(this.terlapors_2)
      }
    },
    cekTerlapors3() {
      if (this.terlapors_3 == false) {
        this.terlapors_3 = true
        // console.log(this.terlapors_3)
      }else{
        this.terlapors_3 = false
        // console.log(this.terlapors_3)
      }
    },

    updateParaPihak() {
      this.$emit('emitUpdateParaPihak')
    },
    async changeBadanPeradilan(val)
    {
      // console.log(val)
      this.localData.badan_peradilan_tk_1 = val
      // const data = {
      //   badan_peradilan_tk_1: val
      // }
      //  this.badanPeradilanTingkat1Options = {
      //   selected: 'id',
      //   options: [
      //     { value: null, label: '--Pilih Kota --' },
      //   ],
      // }
      // this.kotaOptions = {
      //   selected: 'id',
      //   options: [
      //     { value: null, label: '--Pilih Kota --' },
      //   ],
      // }
      // const respKota = await getKota(data)
      // this.mappingSelectOption(val, this.badanPeradilanTingkat1Options.options)
    },
    async hapusData(id){
      if(window.confirm('Apakah anda yakin?'))
      {
        const resp = await hapusPihak(id)
        if(resp.data.success === true)
        {
          this.makeToast('success', resp.data.message)
          this.$emit('emitGetDetailDataKerjasama', this.$route.params.id)
        }
      }
    },
    reloadDataKerjasama()
    {
      this.$emit('emitGetDetailDataKerjasama', this.$route.params.id)
    },
    makeToast(variant = null, message = null) {
      this.$bvToast.toast(message, {
        title: 'Kerjasama',
        variant,
        solid: true,
        autoHideDelay: 5000,
      })
    },
    mappingSelectOption(master, name) {
      if (master.length > 0) {
        master.forEach(element => {
          name.push({
            label: element.nama,
            value: element.id,
          })
        })
      }
    },
  }
}
</script>
