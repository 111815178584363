<template>
  <div>
    
    <form-wizard
      color="#7367F0"
      :title="null"
      :subtitle="null"
      shape="square"
      :finish-button-text="($route.params.laporan_id && !$route.params.penerimaan ? 'Simpan Data Putusan' : ($route.params.penerimaan == 'generate' ? 'Generate nomor penerimaan' : 'Buat Laporan'))"
      back-button-text="Kembali"
      next-button-text="Lanjut"
      class="mb-3"
      @on-complete="formSubmitted"
    >
   

      <!-- accoint details tab -->
      <tab-content
        :title="$route.params.laporan_id ? 'Data Penerimaan' : 'Buat Laporan'"
        :before-change="validationForm"
      >
        <validation-observer
          ref="accountRules"
          tag="form"
        >
          <b-row>
            <b-col
              cols="12"
              class="mb-2"
            >
              <h5 class="mb-0" v-if="$route.params.laporan_id">
                Data Penerimaan
              </h5>
              <h5 class="mb-0" v-else>
                Laporan
              </h5>
              <small class="text-muted" v-if="$route.params.laporan_id">
                Masukkan Data Penerimaan
              </small>
              <small class="text-muted" v-else>
                Buat Laporan Baru
              </small>
            </b-col>
            <b-col md="6">
              <validation-provider
                #default="{ errors }"
                name="Jenis Penyampaian"
                rules="required"
              >
                <b-form-group
                  label="Jenis Penyampaian *"
                  label-for="Jenis Penyampaian"
                  :state="errors.length > 0 ? false:null"
                >
                  <!-- <v-select
                    :disabled="$route.params.readonly == 'true'"
                    v-model="localData.jenis_penyampaian_id"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    class="w-100"
                    :reduce="val => val.value"
                    :options="jenisPenyampaianOptions"
                    @input="(val) => onChangeJenisPenyampaian(val)"
                  /> -->
                  <v-select
                    :disabled="$route.params.readonly == 'true'"
                    v-model="localData.jenis_penyampaian_id"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    class="w-100"
                    :reduce="val => val.value"
                    :options="jenisPenyampaianOptions"
                  />
                  <b-form-invalid-feedback :state="errors.length > 0 ? false:null">
                    Jenis penyampaian wajib diisi!
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-col>
            <b-col md="6">
                <validation-provider
                  #default="{ errors }"
                  name="Sumber Informasi"
                  rules="required"
                >
                  <b-form-group
                    label="Sumber Informasi *"
                    label-for="Sumber Informasi"
                    :state="errors.length > 0 ? false : null"
                  >
                    <v-select
                      :disabled="$route.params.readonly == 'true'"
                      v-model="localData.informasi.sumber_informasi_id"
                      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                      class="w-100"
                      :reduce="val => val.value"
                      @input="(val) => $emit('emitLoadJenisInformasi', val)"
                      :options="sumberInformasiOptions"
                    />
                    <b-form-invalid-feedback :state="errors.length > 0 ? false : null">
                      Sumber Informasi wajib diisi!
                    </b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>
              </b-col>
              <b-col md="6">
                <validation-provider
                  #default="{ errors }"
                  name="Jenis Informasi"
                >
                  <b-form-group
                    label="Jenis Informasi"
                    label-for="Jenis Informasi"
                    :state="errors.length > 0 ? false : null"
                  >
                    <v-select
                      :disabled="$route.params.readonly == 'true'"
                      v-model="localData.informasi.jenis_informasi_id"
                      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                      class="w-100"
                      :reduce="val => val.value"
                      :options="jenisInformasiOptions"
                    />
                    <b-form-invalid-feedback :state="errors.length > 0 ? false : null">
                      Kota/Kabupaten Lingkungan Peradilan wajib diisi!
                    </b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>
              </b-col>
            
            <b-col md="6">
              <validation-provider
                #default="{ errors }"
                name="Jenis Perkara"
                rules="required"
              >
                <b-form-group
                  label="Jenis Perkara *"
                  label-for="Jenis Perkara"
                  :state="errors.length > 0 ? false:null"
                >
                  <v-select
                    :disabled="$route.params.readonly == 'true'"
                    v-model="localData.jenis_perkara_id"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    class="w-100"
                    :reduce="val => val.value"
                    :options="jenisPerkaraOptions"
                  />
                  <b-form-invalid-feedback :state="errors.length > 0 ? false:null">
                    Jenis Perkara wajib diisi!
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-col>
            <b-col md="6">
              <validation-provider
                #default="{ errors }"
                name="Diterima Melalui"
                rules="required"
              >
                <b-form-group
                  label="Diterima Melalui *"
                  label-for="Diterima Melalui"
                  :state="errors.length > 0 ? false:null"
                >
                  <v-select
                    :disabled="$route.params.readonly == 'true'"
                    v-model="localData.diterima_melalui_id"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    class="w-100"
                    :reduce="val => val.value"
                    :options="diterimaMelaluiOptions"
                  />
                  <b-form-invalid-feedback :state="errors.length > 0 ? false:null">
                    Diterima melalui wajib diisi!
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-col>
            <b-col md="6"
              v-if="localData.kantor_penghubung_id != null || localData.diterima_melalui_id === 3"
            >
              <validation-provider
                #default="{ errors }"
                name="Kantor Penghubung"
                :rules="(localData.kantor_penghubung_id != null || localData.diterima_melalui_id === 3)?'required':''"
              >
                <b-form-group
                  :label="(localData.kantor_penghubung_id != null || localData.diterima_melalui_id === 3) ? 'Kantor Penghubung*' : 'Kantor Penghubung'"
                  label-for="Kantor Penghubung"
                  :state="errors.length > 0 ? false : null"
                >
                  <v-select
                    :disabled="$route.params.readonly == 'true'"
                    v-model="localData.kantor_penghubung_id"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    class="w-100"
                    :reduce="val => val.value"
                    :options="kantorPenghubungOptions"
                  />
                  <b-form-invalid-feedback :state="errors.length > 0 ? false : null">
                    Kantor Penghubung wajib diisi!
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-col>
            <b-col md="6">
                <b-form-group
                label="Nomor Surat"
                label-for="Nomor Surat"
              >
                <!-- <validation-provider
                  #default="{ errors }"
                  name="Nomor Surat"
                > -->
                  <!-- <b-form-input
                    :disabled="$route.params.readonly == 'true'"
                    id="no_surat"
                    v-model="localData.no_surat"
                    type="text"
                    :state="errors.length > 0 ? false:null"
                  /> -->
                  <b-form-input
                    :disabled="$route.params.readonly == 'true'"
                    id="no_surat"
                    v-model="localData.no_surat"
                    type="text"
                  />
                  <!-- <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider> -->
              </b-form-group>
            </b-col>

            <b-col md="6">
              <!-- <validation-provider
                #default="{ errors }"
                name="Tanggal Surat"
              > -->
                <!-- <b-form-group
                  label="Tanggal Surat"
                  label-for="Tanggal Surat"
                  :state="errors.length > 0 ? false:null"
                > -->
                <b-form-group
                  label="Tanggal Surat"
                  label-for="Tanggal Surat"
          
                >
                  <b-form-datepicker v-model="localData.tgl_surat"  :disabled="$route.params.readonly == 'true'" locale="id"/>
                  <!-- <b-form-invalid-feedback :state="errors.length > 0 ? false:null">
                    Tanggal Surat wajib diisi!
                  </b-form-invalid-feedback> -->
                </b-form-group>
              <!-- </validation-provider> -->
            </b-col>
            <b-col md="6">
                <validation-provider
                  #default="{ errors }"
                  name="Lingkungan Peradilan"
                  rules="required"
                >
                  <b-form-group
                    label="Lingkungan Peradilan *"
                    label-for="Lingkungan Peradilan"
                    :state="errors.length > 0 ? false : null"
                  >
                    <v-select
                      :disabled="$route.params.readonly == 'true'"
                      v-model="localData.lingkungan_peradilan_id"
                      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                      class="w-100"
                      :reduce="val => val.value"
                      :options="lingkunganPeradilanOptions"
                    />
                    <b-form-invalid-feedback :state="errors.length > 0 ? false : null">
                      Lingkungan Peradilan wajib diisi!
                    </b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>
              </b-col>
              <b-col md="6">
              <validation-provider
                #default="{ errors }"
                name="Provinsi Lingkungan Peradilan"
                rules="required"
              >
                <b-form-group
                  label="Provinsi Lingkungan Peradilan *"
                  label-for="Provinsi Lingkungan Peradilan"
                  :state="errors.length > 0 ? false:null"
                >
                  <v-select
                    :disabled="$route.params.readonly == 'true'"
                    v-model="localData.provinsi_id"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    class="w-100"
                    :reduce="val => val.value"
                    @input="(val) => $emit('emitLoadKota', val)"
                    :options="provinsiOptions"
                  />
                  <b-form-invalid-feedback :state="errors.length > 0 ? false:null">
                    Provinsi Lingkungan Peradilan wajib diisi!
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-col>
            <b-col md="6">
              <validation-provider
                #default="{ errors }"
                name="Kota/Kabupaten Lingkungan Peradilan"
                rules="required"
              >
                <b-form-group
                  label="Kota/Kabupaten Lingkungan Peradilan *"
                  label-for="Kota/Kabupaten Lingkungan Peradilan"
                  :state="errors.length > 0 ? false:null"
                >
                  <v-select
                    :disabled="$route.params.readonly == 'true'"
                    v-model="localData.kota.id"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    class="w-100"
                    :reduce="val => val.value"
                    :options="kotaOptions"
                  />
                  <b-form-invalid-feedback :state="errors.length > 0 ? false:null">
                    Kota/Kabupaten Lingkungan Peradilan wajib diisi!
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-col>

            <b-col md="12">
              <!-- <validation-provider
                #default="{ errors }"
                name="Perihal"
              > -->
                <!-- <b-form-group
                  label="Perihal"
                  label-for="Perihal"
                  :state="errors.length > 0 ? false:null"
                > -->
                <b-form-group
                  label="Perihal"
                  label-for="Perihal"
                  rules="required"
                >
                  <!-- <b-form-textarea
                    :disabled="$route.params.readonly == 'true'"
                    v-model="localData.perihal"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    cols="30"
                    rows="4"

                    :reduce="val => val.value"
                  /> -->
                  <quill-editor
                    id="Perihal"
                    :disabled="$route.params.readonly == 'true'"
                    :options="snowOption"
                    style="height: 200px; margin-bottom: 100px;"
                    v-model="localData.perihal_data"
                  />
                  <!-- <b-form-invalid-feedback :state="errors.length > 0 ? false:null">
                    Perihal wajib diisi!
                  </b-form-invalid-feedback> -->
                </b-form-group>
              <!-- </validation-provider> -->


                   
                    
                   
              
            </b-col>
          
                <b-col
                  cols="12"
                  md="6"
                  class="mb-2"
                >
                    
                        <b-media no-body>
                        <b-media-body class="mt-60 ml-60">
                          <label>Perihal</label>
                          <div class="d-flex" style="width: 100%;">
                            <b-form-file
                              v-if="!$route.params.readonly"
                              v-model="localData.file_perihal"
                              placeholder="Masukkan File"
                            >
                            </b-form-file>
                            
                          </div>
                          <label style="color: rgb(173, 173, 173);" v-if="!$route.params.readonly">Ukuran file max 5mb</label>
                        </b-media-body>
                      </b-media>
                    
                </b-col>
          </b-row>

        </validation-observer>
      </tab-content>

      <!-- personal details tab -->
      <tab-content v-if="$route.params.laporan_id"
        title="Putusan"
        :before-change="validationFormInfo"
      >
        <validation-observer
          ref="infoRules"
          tag="form"
        >
        <div class="d-flex">
          <putusan 
           @emitgetDetailDataLaporan="emitgetDetailDataLaporan" :majelisHakimOptions="majelisHakimOptions" :jenisLaporanOptions="jenisLaporanOptions"  :badanPeradilanTingkat1Options="badanPeradilanTingkat1Options" :badanPeradilanTingkat2Options="badanPeradilanTingkat2Options" :badanPeradilanTingkat3Options="badanPeradilanTingkat3Options" :localData="localData"/>
        </div>
        </validation-observer>
      </tab-content>


      <!-- address  -->
      <!-- <tab-content
        title="Korespondensi"
        :before-change="validationFormAddress"
      >
        <validation-observer
          ref="addressRules"
          tag="form"
        >
        <div class="d-flex">
          <div>
             <h5 class="mb-0">
              Korespondensi
            </h5>
            <small class="text-muted">Masukkan Korespondensi</small>
          </div>
          <b-button class="btn ml-3" variant="primary" v-if="!$route.params.readonly">+ Pihak</b-button>
        </div>
          <div v-for="item in localData.parapihak" :key="item.id">
            <korespondensi :localData="item" @emitGetDetailDataKerjasama="refreshData" @emitUpdateParaPihak="updateParaPihak" />
          </div>
        </validation-observer>
      </tab-content> -->
    </form-wizard>
  </div>
</template>

<script>
import { FormWizard, TabContent } from 'vue-form-wizard'
import vSelect from 'vue-select'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import 'vue-form-wizard/dist/vue-form-wizard.min.css'
import Kerjasama from '@/views/laporan/list/List.vue'
import {
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BFormInvalidFeedback,
  BButton,
  BFormTextarea,
  BModal,
  BFormFile,
  BMedia,
  BMediaBody,
  BFormDatepicker,
} from 'bootstrap-vue'
import {
  uploadDokumen,
  downloadDokumen,
  tambahPihak,
  // tambahPutusan,
  generateNomorPenerimaan,
  updateDataLaporan,
  createDataLaporan,
  uploadPerihal,
  downloadPerihal,
} from '@/connection-api/laporan'
import {
  postPutusan,
} from '@/connection-api/master'
import { required, email } from '@validations'
import { codeIcon } from './code'
import flatPickr from "vue-flatpickr-component";
import { Indonesian } from "flatpickr/dist/l10n/id.js";
import 'flatpickr/dist/flatpickr.css';
import Korespondensi from "./component/Korespondensi.vue";
import ModalDokumen from './component/ModalDokumen.vue'
import Putusan from './component/Putusan.vue'
import { quillEditor } from 'vue-quill-editor'
import '@vueup/vue-quill/dist/vue-quill.snow.css'
export default {
  components: {
    ValidationProvider,
    ValidationObserver,
    FormWizard,
    TabContent,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    vSelect,
    BFormInvalidFeedback,
    BFormTextarea,
    // eslint-disable-next-line vue/no-unused-components
    ToastificationContent,
    BButton,
    flatPickr,
    BModal,
    BFormFile,
    BMedia,
    BMediaBody,
    Kerjasama,
    Korespondensi,
    ModalDokumen,
    BFormDatepicker,
    Putusan,
    quillEditor,
  },
  data() {
    return {
      snowOption: {
        theme: 'snow',
      },
      configs: {
        basic: {},
        wrap: {
          wrap: true,
          dateFormat: "d-m-Y",
          locale: Indonesian,
        },
      },
      centeredModalVisible: false,
      selectedContry: '',
      selectedLanguage: '',
      name: '',
      emailValue: '',
      PasswordValue: '',
      passwordCon: '',
      first_name: '',
      last_name: '',
      address: '',
      landMark: '',
      pincode: '',
      twitterUrl: '',
      facebookUrl: '',
      googleUrl: '',
      linkedinUrl: '',
      city: '',
      required,
      email,
      codeIcon,
      countryName: [
        { value: 'select_value', text: 'Select Value' },
        { value: 'Russia', text: 'Russia' },
        { value: 'Canada', text: 'Canada' },
        { value: 'China', text: 'China' },
        { value: 'United States', text: 'United States' },
        { value: 'Brazil', text: 'Brazil' },
        { value: 'Australia', text: 'Australia' },
        { value: 'India', text: 'India' },
      ],
      languageName: [
        { value: 'nothing_selected', text: 'Nothing Selected' },
        { value: 'English', text: 'English' },
        { value: 'Chinese', text: 'Mandarin Chinese' },
        { value: 'Hindi', text: 'Hindi' },
        { value: 'Spanish', text: 'Spanish' },
        { value: 'Arabic', text: 'Arabic' },
        { value: 'Malay', text: 'Malay' },
        { value: 'Russian', text: 'Russian' },
      ],
    }
  },
  props: {
    sumberInformasiOptions: {},
    jenisInformasiOptions: {},
    jenisPenyampaianOptions: {},
    kantorPenghubungOptions: {},
    jenisPerkaraOptions: {},
    lingkunganPeradilanOptions: {},
    provinsiOptions: {},
    diterimaMelaluiOptions: {},
    kotaOptions: {},
    jenisDokumenOptions: {},
    jenisPihakTerlibatOptions: {},
    majelisHakimOptions: {},
    jenisLaporanOptions: {},
    // dugaanPelanggaranOptions: {},
    badanPeradilanTingkat1Options: {},
    badanPeradilanTingkat2Options: {},
    badanPeradilanTingkat3Options: {},
    localData: {},
  },
  mounted() {
    if(this.localData.tgl_penerimaan === null || this.localData.tgl_penerimaan === '' ||  this.localData.tgl_penerimaan === 'null')
    { 
      // this.localData.tgl_penerimaan = new Date()
    }

  },
  beforeMount() {
    this.localData.tgl_surat = new Date()

  },
  created(){
    if(this.localData.provinsi_id !== null){
      // console.log(this.localData)
      this.$emit('emitLoadKota', this.localData.provinsi_id)
    }
    if (this.localData.informasi !== null && this.localData.informasi.sumber_informasi_id !== null) {
      // console.log(this.localData)
      this.$emit('emitLoadJenisInformasi', this.localData.informasi.sumber_informasi_id)
    }
    // console.log("this.localData")
    // console.log(this.localData)
  },
  methods: {

    ucwordsAndRemoveId(str) {
      return str.replace(/\b\w/g, function (char) {
        return char.toUpperCase();
      });
    },
    // async onChangeJenisPenyampaian(jenisPenyampaianId) {
    //   const responseJenisPenyamapaian = await generateNomorPenerimaan(jenisPenyampaianId)
    //   if(responseJenisPenyamapaian.data.success === true)
    //   {
    //     this.localData.no_penerimaan = responseJenisPenyamapaian.data.data
    //   }
    // },
    emitgetDetailDataLaporan(){
      this.$emit('emitgetDetailDataLaporan', this.$route.params.laporan_id)
    },
    // async onchangeJenisLaporan(jenis_laporan_id)
    // {
    //   this.$emit('emitLoadDugaanPelanggaran', jenis_laporan_id)
    // },
    changeTanggalMulai(tanggal) {
      const tahun_str = this.localData.masa_berlaku != null || this.localData.masa_berlaku != 0 ? this.localData.masa_berlaku / 12 : 0
      const tahun_number = Math.round(tahun_str)
      const tanggal_awal = tanggal
      const tahun_awal = (parseInt(tanggal_awal.substring(0, 4))).toString()
      const tahun_akhir = (parseInt(tanggal_awal.substring(0, 4)) + tahun_number).toString()
      const result = tanggal_awal.replace(tahun_awal, tahun_akhir)
      this.localData.tanggal_akhir = result
    },
    updateDataKerjasma() {
      this.localData.tahap = 2
      this.$emit('emitUpdateDataKerjasama', this.localData)
    },
    updateParaPihak() {
      this.localData.tahap = 'para-pihak'
      this.$emit('emitUpdateDataKerjasama', this.localData)
    },
    async tambahPihak() {
      const resp = await tambahPihak(this.localData);
      if(resp.data.success === true)
      {
        this.makeToast("success", JSON.stringify(resp.data.message))
        this.updateParaPihak()
        this.refreshData()
      }
      else
      {
        this.makeToast("danger", JSON.stringify(resp.data.message))
      }
    },
    // async tambahPutusan() {
    //   const resp = await tambahPutusan(this.localData);
    //   if(resp.data.success === true)
    //   {
    //     this.makeToast("success", JSON.stringify(resp.data.message))
    //     this.updatePutusan()
    //     this.refreshData()
    //   }
    //   else
    //   {
    //     this.makeToast("danger", JSON.stringify(resp.data.message))
    //   }
    // },
    async lihatData(file) {
      try {
        const resp = await downloadDokumen(file);
        var type_file = file.substring(file.length - 4);
        if (type_file === ".pdf") {
          this.imageFile = URL.createObjectURL(
            new Blob([resp.data], { type: "application/pdf" })
          );
        } else {
          this.imageFile = URL.createObjectURL(
            new Blob([resp.data], { type: "image/jpeg" })
          );
        }
        window.open(this.imageFile);
      } catch (error) {
        this.makeToast("danger", JSON.stringify("File tidak ditemukan!"));
      }
    },
    async downloadPerihal(file) {
      try {
        const resp = await downloadPerihal(file);
        var type_file = file.substring(file.length - 4);
        if (type_file === ".pdf") {
          this.imageFile = URL.createObjectURL(
            new Blob([resp.data], { type: "application/pdf" })
          );
        } else if (type_file === ".docx") {
          this.imageFile = URL.createObjectURL(
            new Blob([resp.data], { type: "application/vnd.openxmlformats-officedocument.wordprocessingml.document" })
          );
        } else if (type_file === ".doc") {
          this.imageFile = URL.createObjectURL(
            new Blob([resp.data], { type: "application/msword" })
            );
        } else {
          this.imageFile = URL.createObjectURL(
            new Blob([resp.data], { type: "image/jpeg" })
          );
        }

        window.open(this.imageFile);
      } catch (error) {
        this.makeToast("danger", JSON.stringify("File tidak ditemukan!"));
      }
    },
    
    openModal()
    {
      const elem = this.$refs.confirm
      elem.click()
    },
    dialogYa()
    {
      const elem = this.$refs.opennota
      elem.click()
      const elem2 = this.$refs.dialog
      elem2.hide()
    },
    dialogBatal()
    {
      const elem = this.$refs.dialog
      elem.hide()
    },
    

    async formSubmitted() { 
      if (!this.$route.params.laporan_id) {
        

        //check if perihal text exist or perihal_file exist
        if (!this.localData.perihal_data && !this.localData.file_perihal) {
          this.makeToast("danger", "Perihal wajib diisi! Silakan mengisi perihal dalam bentuk teks atau upload template perihal!");
        } else {

          if (this.localData.file_perihal) {
            // const responseUploadPerihal = await uploadPerihal(this.localData.file_perihal);
            const responseUploadPerihal = await uploadPerihal(this.localData.file_perihal, event => {
              this.progress = Math.round((100 * event.loaded) / event.total)
            })

            if (responseUploadPerihal.data.status.code == '00') {
              this.localData.nama_file_perihal = responseUploadPerihal.data.filename
              this.localData.perihal_id = responseUploadPerihal.data.id;
            }
          }
          const responseCreate = await createDataLaporan(this.localData)
          if (responseCreate.data.success === true) {
            this.localData.id = responseCreate.data.id
            this.$router.push({
              name: 'penerimaan-edit',
              params: { laporan_id: responseCreate.data.id }
            });
          } else {
            const ucwordsSentence = this.ucwordsAndRemoveId(responseCreate.data.message);
            this.makeToast("danger", ucwordsSentence);
          }
        }


        
      } else {
          if(this.$route.params.laporan_id && !this.$route.params.penerimaan){
            const data1 = {
              laporan_id: this.localData.id,
              hakim_majelis: this.localData.hakim_majelis,
              pengadilan_tingkat: this.localData.pengadilan_tingkat_1 ? '1' : null,
              badan_peradilan: this.localData.badan_peradilan_tk_1 ? this.localData.badan_peradilan_tk_1 : null,
              putusan_pengadilan: this.localData.putusan_pengadilan_tk_1 ? this.localData.putusan_pengadilan_tk_1 : null,
              laporan_terlapors: this.localData.laporan_terlapors_1 ? this.localData.laporan_terlapors_1 : null,
            }

            const data2 = {
              laporan_id: this.localData.id,
              hakim_majelis: this.localData.hakim_majelis,
              pengadilan_tingkat: this.localData.pengadilan_tingkat_2 ? '2' : null,
              badan_peradilan: this.localData.badan_peradilan_tk_2 ? this.localData.badan_peradilan_tk_2 : null,
              putusan_pengadilan: this.localData.putusan_pengadilan_tk_2 ? this.localData.putusan_pengadilan_tk_2 : null,
              laporan_terlapors: this.localData.laporan_terlapors_2 ? this.localData.laporan_terlapors_2 : null,
            }

            const data3 = {
              laporan_id: this.localData.id,
              hakim_majelis: this.localData.hakim_majelis,
              pengadilan_tingkat: this.localData.pengadilan_tingkat_3 ? '3' : null,
              badan_peradilan: this.localData.badan_peradilan_tk_3 ? this.localData.badan_peradilan_tk_3 : null,
              putusan_pengadilan: this.localData.putusan_pengadilan_tk_3 ? this.localData.putusan_pengadilan_tk_3 : null,
              laporan_terlapors: this.localData.laporan_terlapors_3 ? this.localData.laporan_terlapors_3 : null,
            }

            const responseUpdatePutusan1 = await postPutusan(data1)
            const responseUpdatePutusan2 = await postPutusan(data2)
            const responseUpdatePutusan3 = await postPutusan(data3)
            
            if(responseUpdatePutusan1.data.success === true || responseUpdatePutusan2.data.success === true || responseUpdatePutusan3.data.success === true)
            {
              this.makeToast('success', 'Data Putusan Berhasil Disimpan')
            }else if(responseUpdatePutusan1.data.success === false){
              this.makeToast('danger', responseUpdatePutusan1.data.message)
            }
          }else if(this.$route.params.penerimaan){
            if(this.localData.total_pelapors < 1){
              this.makeToast('danger', 'Mohon masukkan pelapor terlebih dahulu!')
            }
            if(this.localData.total_kelengkapans < 1){
              this.makeToast('danger', 'Mohon masukkan kelengkapan data terlebih dahulu!')
            }

          if(
            this.localData.total_pelapors > 0 && this.localData.total_kelengkapans > 0
            // this.localData.total_terlapors > 0 && 
          ){
              const data = {
                laporan_id: this.$route.params.laporan_id,
                jenis_penyampaian_id: this.localData.jenis_penyampaian_id
              };

              const response = await generateNomorPenerimaan(data)
              if(response.data.success === true)
              {
                this.makeToast('success', response.data.message)
                setTimeout(() => {
                  this.$router.push({name: 'laporan-readonly', params: this.$route.params.laporan_id })
                }, 3000);
                
              }else{
                this.makeToast('danger', response.data.message)
                setTimeout(() => {
                  this.$router.push({name: 'laporan-readonly', params: this.$route.params.laporan_id })
                }, 3000);
              }
          }
        
        }
      }
    },
    validationForm() {
      return new Promise((resolve, reject) => {
        this.$refs.accountRules.validate().then(success => {
          if(this.$route.params.readonly)
          {
            resolve(true)
          }
          else
          {
            if (success) {
              // this.localData.tahap = 1
              this.$emit('emitUpdateDataKerjasama', this.localData)
              // this.$emit('emitGetDetailDataKerjasama', this.$route.params.id)
              resolve(true)
            } else {
              reject()
            }
          }
        })
      })
    },
    validationFormInfo() {
      return new Promise((resolve, reject) => {
        this.$refs.infoRules.validate().then(success => {
          if(this.$route.params.readonly)
          {
            resolve(true)
          }
          else
          {
            if (success) {
              // this.localData.tahap = 2
              // this.$emit('emitUpdateDataLaporan', this.localData)
              // // this.$emit('emitGetDetailDataKerjasama', this.$route.params.id)
              resolve(true)
            } else {
              reject()
            }
          }
        })
      })
    },
    validationFormAddress() {
      return new Promise((resolve, reject) => {
        this.$refs.addressRules.validate().then(success => {
          if(this.$route.params.readonly)
          {
            resolve(true)
          }
          else
          {
             if (success) {
                this.localData.tahap = 3
                if(this.$route.params.mode){
                  this.localData.mode = 'admin'
                }
                // this.$emit('emitUpdateDataKerjasama', this.localData)
                // this.$emit('emitGetDetailDataKerjasama', this.$route.params.id)
                // this.makeToast('success', 'Simpan Data Kerjasama Berhasil Dilakukan')
                resolve(true)
              } else {
                reject()
              }
          }
        })
      })
    },
     makeToast(variant = null, message = null) {
      this.$bvToast.toast(message, {
        title: 'Penerimaan',
        variant,
        solid: true,
        autoHideDelay: 5000,
      })
    },
    async updateData(file, jenisDokumen, sub_var) {
      const responseUpload = await uploadDokumen(this.localData.id, file, jenisDokumen, event => {
        this.progress = Math.round((100 * event.loaded) / event.total)
      })
      if(responseUpload.data.success === true)
      {
        this.makeToast('success', 'File berhasil di upload')
      }
      else
      {
        this.makeToast('danger', responseUpload.data.message)
      }
    },
    refreshData()
    {
      this.$emit('emitGetDetailDataKerjasama', this.$route.params.id)
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/quill.scss';
@import '@core/scss/vue/pages/page-blog.scss';
</style>
